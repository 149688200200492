import React from 'react'
import { graphql } from 'gatsby'
import { Global } from '@emotion/core'
import styled from '@emotion/styled'

import { GhostPage } from 'types/ghost'
import Layout from 'components/Layout'
import Header from 'components/Header'
import { markdown, centeredContent, typography } from 'styles'

const PageHeading = styled.h1({
  ...typography.headline1,
  margin: '2rem 0 1.6rem -1px',
})

interface Props {
  data: {
    ghostPage: GhostPage
  }
}

const PageContent = styled.div(centeredContent(), { marginBottom: '4.8rem' })

const PageTemplate = ({ data: { ghostPage: page } }: Props) => (
  <Layout>
    <Global
      styles={{
        html: {
          fontSize: '90%',
          '@media (min-width: 760px)': {
            fontSize: '100%',
          },
        },
      }}
    />

    <Header />

    <div css={centeredContent()}>
      <PageHeading>{page.title}</PageHeading>
    </div>
    <PageContent
      css={markdown}
      dangerouslySetInnerHTML={{ __html: page.html }}
    />
  </Layout>
)

export default PageTemplate

export const query = graphql`
  query($ghostId: String!) {
    ghostPage(ghostId: { eq: $ghostId }) {
      ghostId
      title
      html
    }
  }
`
